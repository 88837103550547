<template>
  <span v-if="timer.ms >= 1">
    <span class="items" :class="{ 'no-styles': noStyles }">
      <span v-if="timer.days" class="item">{{ timer.days }}d</span>
      <span v-if="timer.hours" class="item">{{ timer.hours }}h</span>
      <span v-if="timer.minutes" class="item">{{ timer.minutes }}m</span>
      <span v-if="timer.seconds" class="item">{{ timer.seconds }}s</span>
    </span>
  </span>
</template>

<script setup>
import { ref } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useIntervalFn } from '@vueuse/core';

const props = defineProps({
  date: {
    type: String,
    default: '',
  },
  isFrom: {
    type: Boolean,
    default: false,
  },
  noStyles: {
    type: Boolean,
    default: false,
  },
});

dayjs.extend(duration);

const timer = ref({ ms: 999999 });

const formatTime = (time) => (time >= 10 ? time : `0${time}`);

const getDuration = () => {
  const durationMilliSeconds = dayjs(props.isFrom ? undefined : props.date).diff(dayjs(props.isFrom ? props.date : undefined));
  const durationTime = dayjs.duration(durationMilliSeconds);
  const durationDays = Math.floor(durationTime.asDays());

  timer.value = {
    days: durationDays > 0 ? durationDays : null,
    hours: durationTime.hours() > 0 ? formatTime(durationTime.hours()) : null,
    minutes: durationTime.minutes() >= 0 ? formatTime(durationTime.minutes()) : null,
    seconds: durationTime.seconds() >= 0 ? formatTime(durationTime.seconds()) : null,
    ms: durationMilliSeconds,
  };
};

getDuration();

useIntervalFn(() => {
  getDuration();
}, 1000);
</script>

<style lang="scss" scoped>
.items {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:not(.no-styles) .item {
    background: #F2F2F2;
    border-radius: 4px;
    padding: 4px;
  }
}
</style>
