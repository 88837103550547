<template>
  <div class="active">
    <template v-if="isStickiesLoaded">
      <StickyItem v-for="(item, i) in active" :key="i" :item="item" />
      <AddStickyButton v-if="isPossibleAddSticky" />
    </template>
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';

import { getActiveStickies } from '@/api/admin/stickies';
import { useStickies } from '@/composables/useStickies';
import { useBus } from '@/composables/useBus';

import StickyItem from './components/StickyItem.vue';
import AddStickyButton from './components/AddStickyButton.vue';

const { active, isStickiesLoaded } = useStickies();

const isPossibleAddSticky = computed(() => active.value.length < 2 && (active.value[0] ? active.value[0].audience.length < 2 : true));

onBeforeMount(() => {
  const { emitHandler } = useBus();
  emitHandler('update-stickies-page', () => {
    getActiveStickies();
  });
});
</script>

<style lang="scss" scoped>
.active {
  min-height: 274px;
  border-bottom: 1px solid var(--color-black-01);
  padding: 40px;
  background: url("~@/assets/icons/stickies-bg.svg");
  background-size: 32px 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
</style>
